
import { mapState } from "vuex";
import VPagination from "@/components/VPagination.vue";
import { FETCH_SEARCH_RESULTS } from "@/store/actions.type";
import { defineComponent, State } from "vue";

export default defineComponent({
  name: "CSSearchResults",
  components: {
    VPagination
  },
  props: {
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    }
  },
  data() {
    return {
      currentPage: 1,
      searchText: "" as string | string[],
      loading: false,
      error: "" as any
    };
  },
  async mounted() {
    this.error = "";
    this.loading = true;

    if (this.$route.params.searchText) {
      this.searchText = this.$route.params.searchText;
    }

    try {
      this.fetchSearchResults();

      this.error = "";
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      companies: (state) => (state as State).searchResults.companies,
      users: (state) => (state as State).searchResults.users,
      searchResultsPagination: (state) =>
        (state as State).searchResults.searchResultsPagination
    }),
    listConfig() {
      const filters = {
        paginationInformation: {
          currentPage: this.currentPage,
          itemsPerPage: this.itemsPerPage
        },
        searchText: "" as string | string[]
      };

      if (this.searchText) {
        filters.searchText = this.searchText;
      }

      return filters;
    }
  },
  watch: {
    currentPage() {
      this.fetchSearchResults();
    },
    searchText() {
      this.resetPagination();
      this.fetchSearchResults();
    },
    "$route.params.searchText": function (newValue) {
      this.searchText = newValue;
    }
  },
  methods: {
    fetchSearchResults() {
      this.$store.dispatch(FETCH_SEARCH_RESULTS, this.listConfig);
    },
    goToCompany(entityId) {
      this.$router.push({ name: "companies", params: { companyId: entityId } });
    },
    goToUser(entityId) {
      this.$router.push({ name: "users", params: { userId: entityId } });
    },
    resetPagination() {
      this.currentPage = 1;
    }
  }
});
